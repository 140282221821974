import navmenu from '../navmenu/navmenu.vue';
import { AUTH_LOGOUT, AUTH_EXACHANGEUSER } from '../../store/actions/authactions';
import { mapActions, mapGetters } from 'vuex';
import axios from 'axios';
import toastr from 'toastr';

export default {
    components: {
        navmenu
    },
    data() {
        return {
            available: true,
            record: {}
        }
    },
    mounted() {
        this.fetch();
        setInterval(() => this.fetch(), 60000);
    },
    name: 'App',
    computed: {
        ...mapGetters('auth', ['isAuthenticated', 'user', 'useradmin', 'isAdministrator'])
    },
    methods: {
        fetch() {
            if (this.isAuthenticated && !this.isAdministrator) {
                axios.get(APIURL + '/workerinstruction/current/')
                    .then(resp => {
                        this.available = resp.data.length == 0;
                    });
            }
        },
        logout() {
            this.doLogout()
                .then(() => {
                    location.href='/';
                    // this.$router.push({ name: 'login' });
                });
        },
        updateworkerinstruction(checked) {
            if (!this.available) {                
                axios.get(APIURL + '/workerinstruction/current/?create=true')
                    .then(resp => {
                        this.record = resp.data;   
                        toastr.success(this.$t('success.saveChanges'), '');
                    });
            }
            else {
                this.record.isDeleted = true;
                axios.post(APIURL + '/workerinstruction/currentupdate/', this.record)
                    .then(resp => {                        
                        //this.workerinstruction = false;
                    });
            }
        },
        ...mapActions('auth', {
            doLogout: AUTH_LOGOUT,
            exchangeuser: AUTH_EXACHANGEUSER
        }),
    }
};