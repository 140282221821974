import Vue from 'vue';
import FormGroup from '../components/common/formgroup.vue';
import DatePicker from '../components/common/inputs/datepicker.vue';
import Search from '../components/common/inputs/search.vue';
import { default as Vuedals, Component as Vuedal, Bus as VuedalsBus } from 'vuedals';
import VueQuillEditor from 'vue-quill-editor';
//import ImageResize from 'quill-image-resize-module';
import QuillEdit from '../components/common/inputs/quill.vue';
import ReadonlyText from '../components/common/readonly/readonlytext.vue';
import Paging from '../components/common/paging.vue';
import TimePicker from '../components/common/inputs/timepicker.vue';
import InputToggle from '../components/common/inputs/inputtoggle.vue';
import ProgressDialog from '../components/common/inputs/progressdialog.vue';
Vue.component('paging', Paging);
Vue.component('search', Search);
Vue.component('formgroup', FormGroup);
// Global components
Vue.component('datepicker', DatePicker);
Vue.component('timepicker', TimePicker);
Vue.use(Vuedals);
Vue.component('vuedal', Vuedal);
Vue.component('quill', QuillEdit);
Vue.component('readonlytext', ReadonlyText);
Vue.component('inputtoggle', InputToggle);
Vue.component('progressdialog', ProgressDialog);
Vue.use(VueQuillEditor);
//Quill.register('modules/imageResize', ImageResize);

// Register a global custom directive called `v-focus`
Vue.directive('focus', {
    inserted: function (el) {
        el.focus();
    }
});

import VuejsDialog from 'vuejs-dialog';
Vue.use(VuejsDialog, {
    html: true,
    loader: false,
    okText: 'Ok',
    cancelText: 'Annuleren',
    animation: '',
});