import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import i18n from './i18n.js';

Vue.use(VueRouter);

const lazyRoutes = {
    login: () => import(/* webpackChunkName: "login" */ '../components/login/login.vue'),
    setPassword: () => import(/* webpackChunkName: "login" */ '../components/login/setpassword.vue'),
    forgotPassword: () => import(/* webpackChunkName: "login" */ '../components/login/forgotpassword.vue'),
    validateaccount: () => import(/* webpackChunkName: "login" */ '../components/login/validateaccount.vue'),

    home: () => import(/* webpackChunkName: "home" */ '../components/home/home.vue'),

    feedback: () => import(/* webpackChunkName: "login" */ '../components/feedback/feedback.vue'),
    addfeedback: () => import(/* webpackChunkName: "login" */ '../components/feedback/addfeedback.vue'),
    confirmfeedback: () => import(/* webpackChunkName: "login" */ '../components/feedback/feedbackconfirm.vue'),

    companies: () => import(/* webpackChunkName: "company" */ '../components/companies/companies.vue'),
    companyList: () => import(/* webpackChunkName: "company" */ '../components/companies/list.vue'),
    companyEdit: () => import(/* webpackChunkName: "company" */ '../components/companies/edit.vue'),
    companyEditGeneral: () => import(/* webpackChunkName: "company" */ '../components/companies/editgeneral.vue'),
    workerList: () => import(/* webpackChunkName: "company" */ '../components/companies/worker.vue'),
    message: () => import(/* webpackChunkName: "company" */ '../components/message/message.vue'),
    messageList: () => import(/* webpackChunkName: "company" */ '../components/message/list.vue'),
    messageEdit: () => import(/* webpackChunkName: "company" */ '../components/message/edit.vue'),

    invoice: () => import(/* webpackChunkName: "company" */ '../components/invoice/invoice.vue'),
    invoiceList: () => import(/* webpackChunkName: "company" */ '../components/invoice/list.vue'),
    invoiceEdit: () => import(/* webpackChunkName: "company" */ '../components/invoice/edit.vue'),

    workerInstruction: () => import(/* webpackChunkName: "company" */ '../components/workerinstruction/workerinstruction.vue'),
    workerInstructionList: () => import(/* webpackChunkName: "company" */ '../components/workerinstruction/instructionlist.vue'),
    workerInstructionEdit: () => import(/* webpackChunkName: "company" */ '../components/workerinstruction/edit.vue'),

    worker: () => import(/* webpackChunkName: "company" */ '../components/worker/worker.vue'),
    workerEdit: () => import(/* webpackChunkName: "company" */ '../components/worker/editworker.vue'),
    workerReadonly: () => import(/* webpackChunkName: "company" */ '../components/worker/workerreadonly.vue'),

    workeralert: () => import(/* webpackChunkName: "alert" */ '../components/workeralert/workeralert.vue'),
    workeralertList: () => import(/* webpackChunkName: "alert" */ '../components/workeralert/list.vue'),
    workeralertEdit: () => import(/* webpackChunkName: "alert" */ '../components/workeralert/edit.vue'),

    alert: () => import(/* webpackChunkName: "alert" */ '../components/alert/alert.vue'),
    alertList: () => import(/* webpackChunkName: "alert" */ '../components/alert/list.vue'),
    alertEdit: () => import(/* webpackChunkName: "alert" */ '../components/alert/edit.vue'),

    workerCompany: () => import(/* webpackChunkName: "company" */ '../components/workercompany/workercompany.vue'),
    workerCompanyEdit: () => import(/* webpackChunkName: "company" */ '../components/workercompany/companyinfoedit.vue'),
    workerCompanyReadonly: () => import(/* webpackChunkName: "company" */ '../components/workercompany/companyinfo.vue'),
    workerCompanyInstruction: () => import(/* webpackChunkName: "company" */ '../components/workercompany/companyinstruction.vue'),
    workerCompanyInstructionEdit: () => import(/* webpackChunkName: "company" */ '../components/workercompany/edit.vue'),

    log: () => import(/* webpackChunkName: "manage" */ '../components/manage/logs/log.vue'),
    logList: () => import(/* webpackChunkName: "manage" */ '../components/manage/logs/list.vue'),
    logEdit: () => import(/* webpackChunkName: "manage" */ '../components/manage/logs/edit.vue'),
    manageAdministrator: () =>
        import( /* webpackChunkName: "manage" */ '../components/manage/administrator/administrator.vue'),
    manageAdministratorList: () =>
        import( /* webpackChunkName: "manage" */ '../components/manage/administrator/list.vue'),
    manageAdministratorEdit: () =>
        import( /* webpackChunkName: "manage" */ '../components/manage/administrator/edit.vue'),
    manageUnemployment: () =>
        import( /* webpackChunkName: "manage" */ '../components/manage/unemploymenthistory/unemploymenthistory.vue'),
    manageUnemploymentList: () =>
        import( /* webpackChunkName: "manage" */ '../components/manage/unemploymenthistory/list.vue'),



    staffmembers: () => import(/* webpackChunkName: "company" */ '../components/staffmembers/staffmembers.vue'),
    staffmembersList: () => import(/* webpackChunkName: "company" */ '../components/staffmembers/list.vue'),
    staffmembersWorkerEdit: () => import(/* webpackChunkName: "company" */ '../components/staffmembers/staffmembersedit.vue'),
    staffmembersEdit: () => import(/* webpackChunkName: "company" */ '../components/staffmembers/workeredit.vue'),
    staffmembersReadonly: () => import(/* webpackChunkName: "company" */ '../components/staffmembers/workerreadonly.vue'),
    staffmembersInstruction: () => import(/* webpackChunkName: "company" */ '../components/staffmembers/instruction.vue'),
    staffmembersInstructionList: () => import(/* webpackChunkName: "company" */ '../components/staffmembers/instructionlist.vue'),
    staffmembersInstructionEdit: () => import(/* webpackChunkName: "company" */ '../components/staffmembers/instructionedit.vue'),
    staffmembersAccounts: () => import(/* webpackChunkName: "company" */ '../components/staffmembers/workeraccounts.vue'),
    staffmembersMessages: () => import(/* webpackChunkName: "company" */ '../components/staffmembers/message.vue'),
    staffmembersMessagesList: () => import(/* webpackChunkName: "company" */ '../components/staffmembers/messagelist.vue'),
    staffmembersMessagesEdit: () => import(/* webpackChunkName: "company" */ '../components/staffmembers/messageedit.vue'),
    staffmembersExport: () => import(/* webpackChunkName: "company" */ '../components/staffmembers/export.vue'),
    staffmembersExportMessages: () => import(/* webpackChunkName: "company" */ '../components/staffmembers/exportmessages.vue'),
    reportMessages: () => import(/* webpackChunkName: "report" */ '../components/report/messages.vue'),

};

const ifNotAuthenticated = (to, from, next) => {
    if (!store.getters['auth/isAuthenticated']) {
        next();
        return;
    }
    next('/');
};

const ifAuthenticated = (to, from, next) => {
    if (store.getters['auth/isAuthenticated']) {
        next();
        return;
    }
    next('/login');
};

const routes = [
    {
        name: 'login',
        path: i18n.t('path.login'),
        component: lazyRoutes.login,
        beforeEnter: ifNotAuthenticated,
        meta: { title: 'title.portal' }
    },
    {
        name: 'forgotpassword',
        path: i18n.t('path.forgotpassword'),
        component: lazyRoutes.forgotPassword,
        meta: { title: 'title.portal' }
    },
    {
        name: 'validateaccount',
        path: i18n.t('path.validateaccount'),
        component: lazyRoutes.validateaccount,
        meta: { title: 'title.portal' }
    },
    {
        name: 'home',
        path: i18n.t('path.home'),
        component: lazyRoutes.home,
        beforeEnter: ifAuthenticated,
        meta: { title: 'title.portal' }
    },
    {
        name: 'setpassword',
        path: i18n.t('path.setpassword'),
        component: lazyRoutes.setPassword,
        meta: { title: 'title.setpassword' }
    },
    {
        path: i18n.t('path.feedback'),
        component: lazyRoutes.feedback,
        beforeEnter: ifAuthenticated,
        children: [
            {
                name: 'feedback',
                path: '',
                component: lazyRoutes.addfeedback,
                meta: { title: 'title.feedback' },
                props: true
            },
            {
                name: 'confirmfeedback',
                path: 'thankyou',
                component: lazyRoutes.confirmfeedback,
                meta: { title: 'title.feedback' },
                props: true
            },
        ]
    },
    // {
    //     name: 'workers',
    //     path: i18n.t('path.workers'),
    //     beforeEnter: ifAuthenticated,
    //     component: lazyRoutes.workerList,
    //     meta: { title: 'title.workers' }
    // },
    {
        path: i18n.t('path.companies'),
        component: lazyRoutes.companies,
        beforeEnter: ifAuthenticated,
        children: [
            {
                name: 'companies',
                path: '',
                component: lazyRoutes.companyList,
                meta: { title: 'title.companies' },
                props: true
            },
            {
                path: ':companyId',
                component: lazyRoutes.companyEdit,
                props: true,
                children: [
                    {
                        name: 'companyEdit',
                        path: '',
                        component: lazyRoutes.companyEditGeneral,
                        meta: { title: 'title.companies' },
                        props: true
                    },
                    {
                        name: 'companyWorkers',
                        path: i18n.t('path.workers'),
                        component: lazyRoutes.workerList,
                        meta: { title: 'title.companies' },
                        props: true
                    },
                ]
            },
        ]
    },
    {
        path: i18n.t('path.message'),
        component: lazyRoutes.message,
        beforeEnter: ifAuthenticated,
        children: [
            {
                name: 'message',
                path: '',
                component: lazyRoutes.messageList,
                meta: { title: 'title.message' },
                props: true
            },
            {
                name: 'messageEdit',
                path: ':id',
                component: lazyRoutes.messageEdit,
                meta: { title: 'title.message' },
                props: true
            },
        ]
    },
    {
        path: i18n.t('path.log'),
        component: lazyRoutes.log,
        beforeEnter: ifAuthenticated,
        children: [
            {
                name: 'log',
                path: '',
                component: lazyRoutes.logList,
                meta: { title: 'title.log' },
                props: true
            },
            {
                name: 'logEdit',
                path: ':id',
                component: lazyRoutes.logEdit,
                meta: { title: 'title.log' },
                props: true
            },
        ]
    },
    {
        path: i18n.t('path.manage_administrator'),
        component: lazyRoutes.manageAdministrator,
        beforeEnter: ifAuthenticated,
        children: [{
            name: 'administrator',
            path: '',
            component: lazyRoutes.manageAdministratorList,
            meta: { title: 'title.administrator' }
        },
        {
            name: 'administratorEdit',
            path: ':id',
            component: lazyRoutes.manageAdministratorEdit,
            meta: { title: 'title.administrator' }
        }
        ]
    },
    {
        path: i18n.t('path.manage_unemployementhistory'),
        component: lazyRoutes.manageUnemployment,
        beforeEnter: ifAuthenticated,
        children: [{
            name: 'unemploymenthistory',
            path: '',
            component: lazyRoutes.manageUnemploymentList,
            meta: { title: 'title.unemploymenthistory' }
        },

        ]
    },
    {
        path: i18n.t('path.invoice'),
        component: lazyRoutes.invoice,
        beforeEnter: ifAuthenticated,
        children: [
            {
                name: 'invoice',
                path: '',
                component: lazyRoutes.invoiceList,
                meta: { title: 'title.invoice' },
                props: true
            },
            {
                name: 'invoiceEdit',
                path: ':id',
                component: lazyRoutes.invoiceEdit,
                meta: { title: 'title.invoice' },
                props: true
            },
        ]
    },
    {
        path: i18n.t('path.workerinstruction'),
        component: lazyRoutes.workerInstruction,
        beforeEnter: ifAuthenticated,
        children: [
            {
                name: 'workerinstruction',
                path: '',
                component: lazyRoutes.workerInstructionList,
                meta: { title: 'title.workerinstruction' },
                props: true
            },
            {
                name: 'workerinstructionEdit',
                path: ':id',
                component: lazyRoutes.workerInstructionEdit,
                meta: { title: 'title.workerinstruction' },
                props: true
            },
        ]
    },
    {
        path: i18n.t('path.worker'),
        component: lazyRoutes.worker,
        beforeEnter: ifAuthenticated,
        children: [
            {
                name: 'worker',
                path: '',
                component: lazyRoutes.workerReadonly,
                meta: { title: 'title.worker' },
                props: true
            },
            {
                name: 'workerEdit',
                path: ':id',
                component: lazyRoutes.workerEdit,
                meta: { title: 'title.worker' },
                props: true
            },

        ]
    },
    {
        path: i18n.t('path.alert'),
        component: lazyRoutes.alert,
        beforeEnter: ifAuthenticated,
        children: [
            {
                name: 'alert',
                path: '',
                component: lazyRoutes.alertList,
                meta: { title: 'title.alert' },
                props: true
            },
            {
                name: 'alertEdit',
                path: ':id',
                component: lazyRoutes.alertEdit,
                meta: { title: 'title.alert' },
                props: true
            },
        ]
    },
    {
        path: i18n.t('path.workeralert'),
        component: lazyRoutes.workeralert,
        beforeEnter: ifAuthenticated,
        children: [
            {
                name: 'workeralert',
                path: '',
                component: lazyRoutes.workeralertList,
                meta: { title: 'title.alert' },
                props: true
            },
            {
                name: 'workeralertEdit',
                path: ':id',
                component: lazyRoutes.workeralertEdit,
                meta: { title: 'title.alert' },
                props: true
            },
        ]
    },
    {
        path: i18n.t('path.workercompany'),
        component: lazyRoutes.workerCompany,
        beforeEnter: ifAuthenticated,
        children: [
            {
                name: 'workercompany',
                path: '',
                component: lazyRoutes.workerCompanyReadonly,
                meta: { title: 'title.workerinstruction' },
                props: true
            },
            {
                name: 'workerCompanyEdit',
                path: 'editcompany',
                component: lazyRoutes.workerCompanyEdit,
                meta: { title: 'title.workerinstruction' },
                props: true
            },

            {
                name: 'workerCompanyInstruction',
                path: 'companyinstruction/',
                component: lazyRoutes.workerCompanyInstruction,
                meta: { title: 'title.workerinstruction' },
                props: true
            },
            {
                name: 'workerCompanyInstructionEdit',
                path: 'companyinstruction/:id',
                component: lazyRoutes.workerCompanyInstructionEdit,
                meta: { title: 'title.workerinstruction' },
                props: true
            },

        ]
    },
    {
        path: i18n.t('path.staffmembers'),
        component: lazyRoutes.staffmembers,
        beforeEnter: ifAuthenticated,
        children: [
            {
                name: 'staffmembers',
                path: '',
                component: lazyRoutes.staffmembersList,
                meta: { title: 'title.worker' },
            },
            {
                name: 'staffmembersAccounts',
                path: 'accounts/',
                component: lazyRoutes.staffmembersAccounts,
                meta: { title: 'title.worker' },
                props: true
            },
            {
                path: ':id',
                component: lazyRoutes.staffmembersWorkerEdit,
                props: true,
                children: [
                    {
                        name: 'staffmembersEdit',
                        path: '',
                        component: lazyRoutes.staffmembersEdit,
                        meta: { title: 'title.worker' },
                        props: true
                    },
                    {
                        name: 'staffmembersReadonly',
                        path: 'readonly',
                        component: lazyRoutes.staffmembersReadonly,
                        meta: { title: 'title.worker' },
                        props: true
                    },
                    {
                        path: 'instruction/',
                        component: lazyRoutes.staffmembersInstruction,
                        props: true,
                        children: [
                            {
                                name: 'staffmembersInstructions',
                                path: '',
                                component: lazyRoutes.staffmembersInstructionList,
                                meta: { title: 'title.workerinstruction' },
                                props: true
                            },
                            {
                                name: 'staffmembersInstructionEdit',
                                path: ':instuctionid',
                                component: lazyRoutes.staffmembersInstructionEdit,
                                meta: { title: 'title.workerinstruction' },
                                props: true
                            },
                        ]
                    },

                    {
                        path: 'messages/',
                        component: lazyRoutes.staffmembersMessages,
                        props: true,
                        children: [
                            {
                                name: 'staffmembersMessage',
                                path: '',
                                component: lazyRoutes.staffmembersMessagesList,
                                meta: { title: 'title.message' },
                                props: true
                            },
                            {
                                name: 'staffmembersMessageEdit',
                                path: ':messageid',
                                component: lazyRoutes.staffmembersMessagesEdit,
                                meta: { title: 'title.message' },
                                props: true
                            },
                        ]
                    },

                ]
            },
            {

                path: '/exports/',
                component: lazyRoutes.staffmembersExport,
                meta: { title: 'title.worker' },
                props: true,
                children: [
                    {
                        name: 'staffmembersExport',
                        path: '',
                        component: lazyRoutes.staffmembersExportMessages,
                        meta: { title: 'title.worker' },
                        props: true
                    },]
            },
        ]
    },
    {
        name: 'reportMessages',
        path: 'report/messages',
        component: lazyRoutes.reportMessages,
        beforeEnter: ifAuthenticated
    },
];

const router = new VueRouter({ mode: 'hash', routes });

router.afterEach((to, from) => {
    document.title = i18n.t(to.meta.title);
});

export default router;