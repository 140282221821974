import { DELETEDLIST_PAGE, DELETEDLIST_SEARCH } from '../actions/deletedlistactions';

const state = { paged: 0, searchd: '' };

const getters = {
    paged: state => state.paged,
    searchd: state => state.searchd
};

const actions = {
    [DELETEDLIST_SEARCH]: ({ commit }, searchd) => {
        commit(DELETEDLIST_SEARCH, searchd);
    },
    [DELETEDLIST_PAGE]: ({ commit }, paged) => {
        commit(DELETEDLIST_PAGE, paged);
    },
};

const mutations = {
    [DELETEDLIST_PAGE]: (state, paged) => {
        state.paged = paged;
    },
    [DELETEDLIST_SEARCH]: (state, searchd) => {
        state.paged = 0;
        state.searchd = searchd;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,

};