import { AUTH_LOGOUT, AUTH_EXACHANGEUSER } from '../../store/actions/authactions';
import { mapActions, mapGetters } from 'vuex';
import axios from 'axios';
export default {
    data() {
        return {
            unreadcount: 0
        }
    },
    name: 'NavMenu',
    computed: {
        ...mapGetters('auth', ['isAuthenticated', 'user', 'useradmin', 'isWorkerUser', 'isWorkerAdmin', 'isAdministrator'])
    },
    mounted() {
        this.$eventbus.$on('unreadmessage-after-save', this.getUnreadCount);
        this.getUnreadCount();
    },
    methods: {
        logout: function () {
            this.doLogout()
                .then(() => {
                    location.href='/';
                    // this.$router.push({ name: 'login' });
                });
        },
        adminlogin: function () {
            this.exchangeuser({ username: this.user.username })
                .then(() => {
                    
                    if (this.isAdministrator) {
                        this.$router.push({ name: 'companies' });
                    }
                    else {
                        this.$router.push({ name: 'message' });
                    }
                });
        },
        ...mapActions('auth', {
            doLogout: AUTH_LOGOUT,
            exchangeuser: AUTH_EXACHANGEUSER
        }),
        subIsActive(input) {
            const paths = Array.isArray(input) ? input : [input];
            return paths.some(path => {
                return this.$route.path.indexOf(path) === 0; // current path starts with this path string
            });
        },
        getUnreadCount() {
            let url = APIURL + '/phonenote/unreadcount/';
            axios.get(url)
                .then(resp => {
                    this.unreadcount = resp.data;
                });
        }
    }
};