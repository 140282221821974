import { ARCHIVELIST_PAGE, ARCHIVELIST_SEARCH } from '../actions/archivelistactions';

const state = { pagea: 0, searcha: '' };

const getters = {
    pagea: state => state.pagea,
    searcha: state => state.searcha,
};

const actions = {
    [ARCHIVELIST_PAGE]: ({ commit }, pagea) => {
        commit(ARCHIVELIST_PAGE, pagea);
    },
    [ARCHIVELIST_SEARCH]: ({ commit }, searcha) => {
        commit(ARCHIVELIST_SEARCH, searcha);
    },
};

const mutations = {
    [ARCHIVELIST_PAGE]: (state, pagea) => {
        state.pagea = pagea;
    },
    [ARCHIVELIST_SEARCH]: (state, searcha) => {
        state.pagea = 0;
        state.searcha = searcha;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};