import axios from 'axios';
import Vue from 'vue';
import './css/app.scss';
import toastr from 'toastr';
import store from './store';

import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
if (PRODUCTION) {
    Sentry.init({
        dsn: 'https://5e776a71ad9c4ac9bd71998513004283@sentry.io/1445959',
        integrations: [new Integrations.Vue({Vue, attachProps: true})],
        environment: PRODUCTION ? 'Production' : 'Development',
        release: 'telefoonaanname-customer-portal@1.3.8'
    });
}

Vue.config.productionTip = false;

import i18n from './vue/i18n.js';

import nl from 'vee-validate/dist/locale/nl';
import VeeValidate, { Validator } from 'vee-validate';

// Localize takes the locale object as the second argument (optional) and merges it.
//Validator.localize('nl', nl);

const validationConfig = {
    aria: true,
    classNames: {
        valid: 'is-valid',
        invalid: 'is-invalid'
    },
    classes: true,
    // delay: 0,
    dictionary: {
        nl:nl
    },
    // errorBagName: 'errors', // change if property conflicts
     events: 'input|blur',
    // fieldsBagName: 'fields',
    i18n: i18n, // the vue-i18n plugin instance
    i18nRootKey: 'validations', // the nested key under which the validation messages will be located
    // inject: true,
    // locale: 'nl',
    // strict: true,
    // validity: false,
};

// password strength
Validator.extend('verify_password', {
    getMessage: () => i18n.t('passwordRequirements'),
    validate: value => {
        var strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*_-])(?=.{6,})');
        return strongRegex.test(value);
    }
});

Vue.use(VeeValidate, validationConfig);

import VTooltip from 'v-tooltip'

Vue.use(VTooltip)
//Vue.use(VeeValidate, {
//    events: 'input|blur',
//});
import './vue/globalcomponents.js';
import './vue/displayFormats.js';

const user = store.getters['auth/user'];
if (user.token) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.token;
}

import moment from 'moment';
import 'moment-timezone';

moment.locale('nl');

const ISO_8601 = /(\d{4}-\d{2}-\d{2})T(\d{2}:\d{2}:\d{2}(.\d{3})?)(Z)?/;
function convertDateStringsToDateObjects(obj) {   
    for (var property in obj) {
        if (obj.hasOwnProperty(property)) {
            if (typeof obj[property] == 'object') {
                convertDateStringsToDateObjects(obj[property]);
            } 
            else {
                if (ISO_8601.test(obj[property])) {
                    obj[property] = moment(obj[property]).tz(i18n.t('format.timezone')).toDate();
                }
            }
        }
    }
    return obj;
}

axios.interceptors.response.use(response => {
    response.data = convertDateStringsToDateObjects(response.data);
    return response;
}, error => Promise.reject(error));

import { AUTH_LOGOUT } from './store/actions/authactions';

axios.interceptors.response.use(response => response,
    error => {
        if (error.response) {
            if (error.response.status == 401) {
                store.dispatch(`auth/${AUTH_LOGOUT}`)
                    .then(() => {
                        router.push({ name: 'login' });
                    });
            }
            else if(error.response.status == 409) {
                toastr.error(i18n.t('error.deleteRecordConflict'),'');
            }
        }

        return Promise.reject(error);
    }
);

import App from './components/app/app.vue';
import router from './vue/router';

const eventbus = new Vue();

Object.defineProperty(Vue.prototype, '$eventbus', {
    get() { return eventbus; }
});

new Vue({
    el: '#app-root',
    router,
    store,
    i18n,
    render: h => h(App)
});