var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("hr"),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _vm.totalPages > 0
        ? _c("div", { staticClass: "col-lg-4 totalPages" }, [
            _vm._v(
              "\n            Totaal: " +
                _vm._s(_vm.totalRecords) +
                " - Pagina " +
                _vm._s(_vm.currentPage + 1) +
                " van " +
                _vm._s(_vm.totalPages) +
                "\n        "
            )
          ])
        : _c("div", { staticClass: "col-lg-4 totalPages" }, [
            _vm._v(
              "\n            Totaal: " +
                _vm._s(_vm.totalRecords) +
                " - Pagina 0 van 0\n        "
            )
          ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-lg-2 right" }, [_vm._t("default")], 2),
      _vm._v(" "),
      _vm.totalPages > 0
        ? _c("div", { staticClass: "col-lg-6 text-right" }, [
            _c("nav", { attrs: { "aria-label": "Page navigation" } }, [
              _c("ul", { staticClass: "pagination" }, [
                _c("li", { staticClass: "page-item" }, [
                  _vm.currentPage > 0
                    ? _c(
                        "a",
                        {
                          staticClass: "cursorpointer page-link",
                          on: {
                            click: function($event) {
                              return _vm.setCurrentPage(_vm.currentPage - 1)
                            }
                          }
                        },
                        [_vm._v("« Vorige")]
                      )
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "page-item" }, [
                  _vm.currentPage + 1 != _vm.totalPages
                    ? _c(
                        "a",
                        {
                          staticClass: "cursorpointer page-link",
                          on: {
                            click: function($event) {
                              return _vm.setCurrentPage(_vm.currentPage + 1)
                            }
                          }
                        },
                        [_vm._v("Volgende »")]
                      )
                    : _vm._e()
                ])
              ])
            ])
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }