var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("header", [
        _c("div", { staticClass: "container-fluid" }, [
          _c("div", { staticClass: "row" }, [
            _vm.isAuthenticated
              ? _c("div", { staticClass: "col-md-6 col-3" }, [_vm._m(0)])
              : _c("div", { staticClass: "col-12 text-lg-left text-center" }, [
                  _vm._m(1)
                ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-6 col-9" }, [
              _vm.isAuthenticated
                ? _c("div", { attrs: { id: "switches" } }, [
                    _c("div", { staticClass: "switch" }, [
                      !_vm.isAdministrator
                        ? _c("div", { staticClass: "inner clearfix" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.available,
                                  expression: "available"
                                }
                              ],
                              attrs: {
                                id: "switch-personal",
                                type: "checkbox"
                              },
                              domProps: {
                                checked: Array.isArray(_vm.available)
                                  ? _vm._i(_vm.available, null) > -1
                                  : _vm.available
                              },
                              on: {
                                change: [
                                  function($event) {
                                    var $$a = _vm.available,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.available = $$a.concat([$$v]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.available = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.available = $$c
                                    }
                                  },
                                  function($event) {
                                    return _vm.updateworkerinstruction(
                                      $event.target.checked
                                    )
                                  }
                                ]
                              }
                            }),
                            _vm._v(" "),
                            _c("label", { attrs: { for: "switch-personal" } })
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(_vm.user.username))])
                    ])
                  ])
                : _vm._e()
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _vm.isAuthenticated
        ? _c("div", { staticClass: "container-fluid" }, [
            _c("div", { staticClass: "padding-main-content" }, [
              _c("div", { staticClass: "row row-mobile" }, [
                _c(
                  "div",
                  { staticClass: "col-lg-2 ", attrs: { id: "main-nav" } },
                  [_c("navmenu")],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-10" }, [
                  _c(
                    "div",
                    { staticClass: "main-body" },
                    [_c("router-view")],
                    1
                  )
                ])
              ])
            ])
          ])
        : _c("div", { staticClass: "bg-login" }, [
            _c(
              "div",
              { staticClass: "container-fluid" },
              [_c("router-view")],
              1
            )
          ]),
      _vm._v(" "),
      _c("vuedal")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "logo authenticated" }, [
        _c("a", { attrs: { href: "/" } }, [
          _c("img", {
            attrs: {
              src: "/images/logo.png",
              alt: "telefoonaanname.nl klantencentrum"
            }
          })
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "menu-button navbar-light" }, [
        _c(
          "button",
          {
            staticClass: "navbar-toggler",
            attrs: {
              type: "button",
              "data-toggle": "collapse",
              "data-target": "#navbarSupportedContent",
              "aria-controls": "navbarSupportedContent",
              "aria-expanded": "false",
              "aria-label": "Toggle navigation"
            }
          },
          [_c("span", { staticClass: "navbar-toggler-icon" })]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "logo" }, [
      _c("a", { attrs: { href: "/" } }, [
        _c("img", {
          attrs: {
            src: "/images/logo.png",
            alt: "telefoonaanname.nl klantencentrum"
          }
        })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }