export default {
    name: 'Paging',
    props: {
        currentPage: { type: Number, default: 0 },
        totalPages: { type: Number, default: 0 },
        totalRecords: { type: Number, default: 0 },
        search: {
            type: String,
            default: ''
        }
    },
    methods: {
        setCurrentPage(page) {
            this.$emit('page-changed', page);
        }
    },
    computed: {
    }
};
