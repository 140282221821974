const nl = {
    'actions': {
        'add': 'Toevoegen',
        'addaccount': 'Account aanvragen',
        'back': 'Terug',
        'backtoadmin': 'Terug naar beheer',
        'createuser': 'Maak gebruiker',
        'close': 'Sluiten',
        'delete': 'Verwijderen',
        'download': 'Downloaden',
        'edit': 'Bewerken',
        'export': 'Exporteren',
        'impersonate': 'Inloggen&nbsp;als',
        'sendinvite': 'Uitnodiging&nbsp;sturen',
        'login': 'Inloggen',
        'logout': 'Uitloggen',
        'next': 'Volgende',
        'previous': 'Vorige',
        'ok': 'Ok',
        'save': 'Opslaan',
        'search': 'Zoeken',
        'submit': 'Versturen',
        'returntologin': 'Terug naar login',
        'resetuser': 'Uitnodiging sturen'
    },
    'confirm': {
        'leaveWithoutSave': '<strong>Weet u zeker dat u deze pagina wilt verlaten?</strong><br />Er zijn wijzigingen die nog niet zijn opgeslagen',
        'delete': '<strong>Weet u het zeker?</strong><br />De gegevens worden definitief verwijderd',
        'changerole': '<strong>Weet u het zeker?</strong><br />De rol van deze gebruiker wordt gewijzigd',
        'archive': '<strong>Weet u het zeker?</strong><br />De gegevens worden gearchiveerd',
    },
    'error': {
        'saveChanges': 'Er is een probleem opgetreden tijdens het opslaan van de gegevens',
        'deleteRecordConflict': 'Dit record kan niet worden verwijderd',
        'emailexists': 'Dit e-mailadres is al in gebruik',
        'emailnotfound': 'E-mail niet gevonden. Neem contact op met Telefoonaanname',
    },
    'format': {
        'dateo': 'D MMMM YYYY',
        'date': 'dd D MMMM YYYY',
        'dateTime': 'dd D MMMM YYYY HH:mm',
        'fullDate': 'LLLL',
        'time': 'HH:mm',
        'timezone': 'UTC'
    },
    'salutation': {
        'M': 'De heer',
        'F': 'Mevrouw',
        'V': 'Mevrouw'
    },
    'gen': {
        'M': 'Man',
        'F': 'Vrouw',
        'V': 'Vrouw'
    },
    'path': {
        'alert': '/alert',
        'home': '/',
        'login': '/login',
        'forgotpassword': '/login/wachtwoordvergeten',
        'setpassword': '/wijzigwachtwoord',
        'companies': '/relatie',
        'feedback': '/feedback',
        'workers': 'medewerkers',
        'message': '/berichten',
        'invoice': '/factuur',
        'staffmembers': '/personeel',
        'staffmembersExport': '/export',
        'worker': '/medewerker',
        'workerinstruction': '/werkinstructie',
        'workercompany': '/werkmaatschappij',
        'workeralert': '/alertmedewerker',
        'log': '/logboek',
        'manage_administrator': '/beheren/beheerder',
        'validateaccount': '/aanmelden',
        'manage_unemployementhistory': '/uitdienstgeschiedenis',
    },
    'periods': {
        'short': {
            'sunday': 'Zo',
            'monday': 'Ma',
            'tuesday': 'Di',
            'wednesday': 'Wo',
            'thursday': 'Do',
            'friday': 'Vr',
            'saturday': 'Za',
            'january': 'Jan',
            'february': 'Feb',
            'march': 'Maa',
            'april': 'Apr',
            'may': 'Mei',
            'june': 'Jun',
            'july': 'Jul',
            'august': 'Aug',
            'september': 'Sep',
            'october': 'Okt',
            'november': 'Nov',
            'december': 'Dec',
        },
        'long': {
            'january': 'Januari',
            'february': 'Februari',
            'march': 'Maart',
            'april': 'April',
            'may': 'Mei',
            'june': 'Juni',
            'july': 'Juli',
            'august': 'Augustus',
            'september': 'September',
            'october': 'Oktober',
            'november': 'November',
            'december': 'December',
        },
        'week': 'Week',
        'month': 'Maand'
    },
    'status': {
        'status': 'Status',
        'loading': 'Laden...',
        'norecords': 'Geen gegevens gevonden',
        'loginfailed': 'Ongeldige combinatie van e-mailadres en wachtwoord',
        'invalidpassword': 'Het wachtwoord is ongeldig',
        'tokenexpired': 'Uw token voor het opnieuw instellen van uw wachtwoord is verlopen',
    },
    'success': {
        'saveChanges': 'De gegevens zijn succesvol opgeslagen',
        'deleteRecord': 'De gegevens zijn succesvol verwijderd',
        'updatepassword': 'Uw wachtwoord is ingesteld. U kunt nu inloggen',
        'resetpassword': 'Reset wachtwoordlink verzonden naar uw e-mailadres!',
        'sentmail': 'Mail succesvol verzonden!',
        'forgotpassword': 'U ontvangt binnen enkele minuten een e-mail met enn link om een nieuw wachtwoord in te stellen.',
        'usercreated': 'Account succesvol aangemaakt'
    },
    'subtitle': {
        'forgotpassword': 'Voer hier uw e-mailadres in. U ontvangt daarna een e-mail om een nieuw wachtwoord in te stellen.'
    },
    'title': {
        'administrator': 'Beheerders',
        'alert': 'Alert',
        'home': 'Home',
        'portal': 'Klantenportaal',
        'setpassword': 'Stel een wachtwoord in',
        'updatepassword': 'Wijzig wachtwoord',
        'testenvironment': 'TESTVERSIE',
        'feedback': 'Feedback',
        'profile': 'Profiel',
        'login': 'Login',
        'emailsent': 'E-Mail VERZONDEN',
        'companies': 'Relaties',
        'workers': 'Medewerkers',
        'message': 'Berichten',
        'invoice': 'Facturen',
        'worker': 'Medewerker',
        'workerinstruction': 'Afwezigheid',
        'companyinstruction': 'Bedrijfsinstructie',
        'termination': 'Medewerker verwijderen of uit dienst melden',
        'log': 'Logboek',
        'createaccount': 'Voer hier uw e-mailadres in. Indien uw gegevens bij ons bekend zijn, ontvangt u een e-mail waarmee u een wachtwoord kunt aanmaken.',
        'unemploymenthistory': 'Uit dienst log',
    },
    'form': {
        'profile': 'Profiel bewerken',
    },
    'warnings': {

    },
    'access': 'Toegang',
    'active': 'Actief',
    'account': 'Account',
    'accounts': 'Gebruikers zonder account',
    'additionaldata': 'Aanvullende gegevens',
    'address': 'Adres',
    'all': 'Alle',
    'alert': 'Alert',
    'amount': 'Bedrag',
    'archived': 'Gearchiveerd',
    'administrator': 'Beheerder',
    'and': 'en',
    'banknumber': 'Bank nummer',
    'blocked': 'Geblokkeerd',
    'buycredits': 'Vacaturebundel',
    'changepassword': 'Wachtwoord wijzigen',
    'city': 'Plaats',
    'company': 'Relatie',
    'companydetail': 'Bedrijfsdetail',
    'code': 'Code',
    'confirmpassword': 'Bevestig wachtwoord',
    'content': 'Inhoud',
    'country': 'Land',
    'currentpassword': 'Huidig wachtwoord',
    'day': 'Dag | Dagen',
    'date': 'Datum',
    'dateoftermination': 'Datum van beëindiging',
    'companyactivity': 'Bedrijfsactiviteit',
    'description': 'Omschrijving',
    'dateofbirth': 'Geboortedatum',
    'dateexpiration': 'Vervaldatum',
    'datestart': 'Startdatum',
    'dateend': 'Einddatum',
    'deleted': 'Verwijderd',
    'download': 'Download',
    'downloadfile': 'Download bestand',
    'email': 'E-mail',
    'emailaddress': 'E-mailadres',
    'externalid': 'Externe Id',
    'exportmessage': 'Berichten exporteren',
    'feedbackinstruction': 'Heeft u vragen of opmerkingen over ons klantenportaal? Laat het ons dan weten via onderstaand formulier.',
    'feedback': 'Feedback',
    'firstname': 'Voornaam',
    'forgotpassword': 'Wachtwoord vergeten?',
    'from': 'Van',
    'function': 'Functie',
    'gender': 'Geslacht',
    'general': 'Algemeen',
    'iban': 'IBAN Nummer',
    'initials': 'Voorletters',
    'inbox': 'Postvak IN',
    'invoice': 'Facturen',
    'invoiceno': 'Factuurnr.',
    'isarchived': 'Is gearchiveerd',
    'instructions': 'Afwezigheid',
    'instructionsmain': 'Uw status is opgeslagen als niet bereikbaar tot het einde van de dag.',
    'instructionssub': 'AANVULLENDE INSTRUCTIES',
    'language': 'Taal',
    'lastname': 'Achternaam',
    'lastchangeexternal': 'Laatste wijziging extern',
    'labeltitle': 'Titel',
    'log': 'Logboek',
    'markasunread': 'Markeren als ongelezen',
    'middlename': 'Tussenvoegsel',
    'messagesms': 'SMS (aan het versturen van SMS-jes kunnen kosten verbonden zijn)',
    'messageapp': 'App',
    'messageemail': 'E-Mail',
    'messages': 'Berichten',
    'month': 'Maand|Maanden',
    'mobile': 'Mobiel',
    'moredetail': 'Meer details',
    'name': 'Naam',
    'newpassword': 'Nieuw wachtwoord',
    'no': 'Nee',
    'none': 'Geen',
    'number': 'Nummer',
    'option': 'Optie',
    'optional': '(OPTIONEEL)',
    'organization': 'Organisatie',
    'password': 'Wachtwoord',
    'passwordRequirements': 'Het wachtwoord dient minimaal 6 tekens lang te zijn en minimaal: 1 hoofdletter, 1 kleine letter, 1 getal en een speciaal teken (b.v. ! @ # $ % ^ & * _ -) te bevatten.',
    'payment': 'Betaling',
    'paymenttype': 'Betalingswijze',
    'pdf': 'PDF',
    'phone': 'Telefoon',
    'phoneforwardingnumber': 'Doorschakelnr.',
    'postaladdress': 'Postadres',
    'postalcode': 'Postcode',
    'public': 'openbaar',
    'recipient': 'Ontvanger',
    'remarks': 'Opmerkingen',
    'reference': 'Referentie',
    'referencecustomer': 'Referentieklant',
    'receivemessagevia': 'Berichten ontvangen via',
    'sendinvitation': 'Stuur uitnodigingsmail',
    'sendpassword': 'Verstuur e-mail',
    'sendercompany': 'Afzender bedrijf',
    'senderemail': 'E-mailadres',
    'senderphone': 'Telefoon',
    'senderaddress': 'Zender adres',
    'sendername': 'Naam afzender',
    'setpassword': 'Stel een wachtwoord in',
    'settings': 'Instellingen',
    'setupaccount': 'ACCOUNT INSTELLEN',
    'subtotal': 'Subtotaal',
    'staffmembers': 'Medewerkers',
    'workerinstruction': 'Afwezigheid',
    'workerdetail': 'Account',
    'street': 'Straat',
    'streetnumber': 'Huisnummer',
    'telephone1': 'Telefoonnummer privé',
    'telephone2': 'Telefoonnummer mobiel',
    'total': 'Totaal',
    'totalexlvat': 'Totaal excl. BTW',
    'totalinclvat': 'Totaal',
    'totalvat': 'BTW',
    'type': 'Soort',
    'to': 'Aan',
    'terminationdescription': 'Geef hier de uitdienst datum op. De geselecteerde medewerkers zullen nadat de opgegeven datum is verstreken uit het klantencentrum worden verwijderd.',
    'unemploymenthistory': 'Uit dienst log',
    'upto': 't/m',
    'uptoandincluding': 'Tot en met',
    'username': 'E-mailadres',
    'user': 'Gebruiker',
    'vatnumber': 'BTW nummer',
    'view': 'Weergave',
    'visitingaddress': 'Bezoekadres',
    'warning': 'Waarschuwing',
    'website': 'Website',
    'workers': 'Gebruikers',
    'worker': 'Medewerkers',
    'workerinstuction': 'Afwezigheid',
    'companyinstruction': 'Bedrijfsinstructie',
    'year': 'Jaar',
    'yes': 'Ja',
    'zipcode': 'Postcode',
    'WorkerAdmin': 'Beheerder',
    'WorkerUser': 'Gebruiker',
    'role': 'Rol',
    'count': 'Aantal'
};

export default nl;