import Vue from 'vue';
import Vuex from 'vuex';
import auth from './modules/auth';
import companylist from './modules/companylist';
import workerlist from './modules/workerlist';
import phonenotelist from './modules/phonenotelist';
import invoicelist from './modules/invoicelist';
import workerinstrucationlist from './modules/workerinstrucationlist';
import companyinstructionlist from './modules/companyinstructionlist';
import alertlist from './modules/alertlist';
import loglist from './modules/loglist';
import administratorlist from './modules/administratorlist';
import archivelist from './modules/archivelist';
import deletedlist from './modules/deletedlist';
import unemployementhistorylist from './modules/unemployementhistorylist';
Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth,
        companylist,
        workerlist,
        phonenotelist,
        invoicelist,
        workerinstrucationlist,
        companyinstructionlist,
        alertlist,
        loglist,
        administratorlist,
        archivelist,
        deletedlist,
        unemployementhistorylist
    },
    strict: !PRODUCTION,
});